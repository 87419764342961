import { Observable, from } from 'rxjs'
import { map } from 'rxjs/operators'
import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { UserManager, UserManagerSettings, User } from 'oidc-client'
import { environment } from 'src/environments/environment'
import { Location } from '@angular/common'

export function getClientSettings(): UserManagerSettings {
    return {
        authority: environment.soeAuth,
        client_id: environment.soeClientId,
        redirect_uri: environment.clientUrl + 'auth.html',
        post_logout_redirect_uri: environment.clientUrl,
        response_type: 'code',
        scope: 'openid',
        silent_redirect_uri: environment.clientUrl + 'silent-renew.html',
        automaticSilentRenew: true,
        accessTokenExpiringNotificationTime: 4,
        // silentRequestTimeout:10000,
        filterProtocolClaims: true,
        loadUserInfo: false,
    }
}

@Injectable()
export class AuthService {
    mgr: UserManager = new UserManager(getClientSettings())
    userLoadededEvent: EventEmitter<User> = new EventEmitter<User>()
    currentUser: User
    loggedIn = false

    constructor(private http: HttpClient, private location: Location) {
        this.mgr
            .getUser()
            .then((user) => {
                if (user) {
                    this.loggedIn = true
                    this.currentUser = user
                    this.userLoadededEvent.emit(user)
                    localStorage.setItem('matricula', user.profile['soe:matricula'])
                    localStorage.setItem('organizacao', user.profile['soe:organizacao'])
                    sessionStorage.setItem('matricula', user.profile['soe:matricula'])
                    sessionStorage.setItem('organizacao', user.profile['soe:organizacao'])
                    sessionStorage.setItem('sub', user.profile.sub)
                    sessionStorage.setItem('token', user.access_token)
                } else {
                    this.loggedIn = false
                }
            })
            .catch((err) => {
                this.loggedIn = false
            })

        this.mgr.events.addUserLoaded((user) => {
            this.currentUser = user
            this.loggedIn = !(user === undefined)
            if (!environment.production) {
                console.log('authService addUserLoaded', user)
            }
        })

        this.mgr.events.addUserUnloaded(() => {
            if (!environment.production) {
                console.log('user unloaded')
            }
            this.loggedIn = false
        })
    }

    isLoggedInObs(): Observable<boolean> {
        return from(this.mgr.getUser()).pipe(
            map<User, boolean>((user) => {
                if (user) {
                    return true
                } else {
                    return false
                }
            })
        )
    }

    clearState() {
        this.mgr
            .clearStaleState()
            .then(function () {
                console.log('clearStateState success')
            })
            .catch(function (e) {
                console.log('clearStateState error', e.message)
            })
    }

    getUser() {
        this.mgr
            .getUser()
            .then((user) => {
                this.currentUser = user
                console.log('got user', user)
                this.userLoadededEvent.emit(user)
            })
            .catch(function (err) {
                console.log(err)
            })
    }

    getUserName(): Observable<string> {
        return from(this.mgr.getUser()).pipe(
            map<User, string>((user) => {
                if (user) {
                    return user.profile.name
                } else {
                    throw new Error('Usuário não autenticado')
                }
            })
        )
    }

    getUserId(): Observable<string> {
        return from(this.mgr.getUser()).pipe(
            map<User, string>((user) => {
                if (user) {
                    return user.profile.sub
                } else {
                    throw new Error('Usuário não autenticado')
                }
            })
        )
    }

    getUserOrg(): Observable<string> {
        return from(this.mgr.getUser()).pipe(
            map<User, string>((user) => {
                if (user) {
                    return user.profile['soe:organizacao']
                } else {
                    throw new Error('Usuário não autenticado')
                }
            })
        )
    }

    isInternoUser(): Observable<boolean> {
        return this.getUserOrg()
            .pipe(
                map<string,boolean>(org => org && org?.trim().toUpperCase() == 'SES')
            )
    }

    isExternoUser(): Observable<boolean> {
        return this.getUserOrg()
            .pipe(
                map<string,boolean>(org => org && org?.trim().toUpperCase() != 'SES')
            )
    }

    removeUser() {
        this.mgr
            .removeUser()
            .then(() => {
                this.userLoadededEvent.emit(null)
                console.log('user removed')
            })
            .catch(function (err) {
                console.log(err)
            })
    }

    startSigninMainWindow() {
        this.mgr
            .signinRedirect({ data: 'some data' })
            .then(function () {
                console.log('signinRedirect done')
            })
            .catch(function (err) {
                console.log(err)
            })
    }
    endSigninMainWindow() {
        this.mgr
            .signinRedirectCallback()
            .then(function (user) {
                console.log('signed in', user)
            })
            .catch(function (err) {
                console.log(err)
            })
    }

    startSignoutMainWindow() {
        this.mgr.getUser().then((user) => {
            return this.mgr
                .signoutRedirect({ id_token_hint: user.id_token })
                .then((resp) => {
                    console.log('signed out', resp)
                })
                .catch(function (err) {
                    console.log(err)
                })
        })
    }

    endSignoutMainWindow() {
        this.mgr
            .signoutRedirectCallback()
            .then(function (resp) {
                console.log('signed out', resp)
            })
            .catch(function (err) {
                console.log(err)
            })
    }

    /**
     * Example of how you can make auth request using angulars http methods.
     * @param options if options are not supplied the default content type is application/json
     */
    authGet<T>(url: string, options?: {}): Observable<T> {
        options = this._setRequestOptions(options)
        return this.http.get<T>(url, options)
    }
    /**
     * @param options if options are not supplied the default content type is application/json
     */
    authPut<T>(url: string, data: any, options?: {}): Observable<T> {
        const body = JSON.stringify(data)
        options = this._setRequestOptions(options)
        return this.http.put<T>(url, body, options)
    }
    /**
     * @param options if options are not supplied the default content type is application/json
     */
    authDelete<T>(url: string, options?: {}): Observable<T> {
        options = this._setRequestOptions(options)
        return this.http.delete<T>(url, options)
    }
    /**
     * @param options if options are not supplied the default content type is application/json
     */
    authPost<T>(url: string, data: any, options?: {}): Observable<T> {
        const body = JSON.stringify(data)
        options = this._setRequestOptions(options)
        return this.http.post<T>(url, body, options)
    }

    private _setRequestOptions(options?: { headers?: HttpHeaders }) {
        if (this.loggedIn) {
            const header = new HttpHeaders({
                Authorization: this.currentUser.token_type + ' ' + this.currentUser.access_token,
            })
            if (options) {
                options.headers = options.headers.append('Authorization', header.get('Authorization'))
            } else {
                options = { headers: header }
                options.headers = options.headers.append('Content-Type', 'application/json')
            }
        }
        return options
    }
}
