export enum SituacaoMunicipioEquipeEnum {
  COMPLETA = "COMPLETA",
  INCOMPLETA = "INCOMPLETA"
}

export enum tipoEquipeMultiEnum {
  MULTI_60H ="Multi 60h",
  EMULTI_AMPLIADA = "eMulti Ampliada",
	EMULTI_COMPLEMENTAR = "eMulti Complementar",
  EMULTI_ESTRATEGICA = "eMulti Estratégica",
  NAO_HOMOLOGADA = "Não homologada"
}

export enum SituacaoUsuarioAdesaoDescricaoEnum {
  EM_PREENCHIMENTO = "Em Preenchimento",
  PENDENTE_VALIDACAO = "Pendente de Validação",
  COM_PENDENCIAS = "Adesão com pendência",
  DEFERIDO = "Deferido",
  INDEFERIDO = "Indeferido",
  EM_VALIDACAO = "Em Validação"
}
